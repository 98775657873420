import React from "react";
import InvestorCategory from "../templates/InvestorDesktop/investorCategory";
import InvestorContactInfo from "../templates/InvestorDesktop/investorContactInfo";
// import Header from "../components/header"
// import Footer from "../components/footer"
import InvestorMainBanner from "../templates/InvestorDesktop/investorMainBanner";
// import InvestorStatistics from "../templates/InvestorDesktop/investorStatistics";
import InvestorWhyInvest from "../templates/InvestorDesktop/investorWhyInvest";
import InvestorShareInfo from "../templates/InvestorDesktop/investorShareInfo";
import Card from "../templates/InvestorDesktop/card";

export default function MediaRoom() {
  return (
    <div>
      {/* <Header /> */}
      <InvestorMainBanner />
      <InvestorWhyInvest />
      <Card />
      <InvestorShareInfo />
      <InvestorCategory />
      <InvestorContactInfo />
      {/* <Footer /> */}
    </div>
  );
}
