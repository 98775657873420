import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./investorContactInfo.css";
import EmailIcon from "../../images/mail-icon.svg";
import PhoneIcon from "../../images/phone-icon.svg";

const contactInfo = [
  {
    name: "Uvini Dias",
    title: "Business Analyst",
    email: "uvini@cic.lk",
    phone: "+94 77 039 4913",
    aosDuration: 500,
  },
  {
    name: "Damodara Wickramanayake",
    title: "CFO CIC Capital/ Chemanex PLC",
    email: "damodara@cic.lk",
    phone: "+94 77 137 9947",
    aosDuration: 1000,
  },
  {
    name: "Viraj Manathunga",
    title: "Group Chief Strategy Officer",
    email: "viraj@cic.lk",
    phone: "+94 77 725 2906",
    aosDuration: 1500,
  },
  {
    name: "Erandi Wickramarachchi",
    title: "Group Chief Financial Officer/Compliance Officer",
    phone: "011 – 2359359 (Ext.201)",
    aosDuration: 2000,
  },
];

function ContactCard({ name, title, email, phone, aosDuration }) {
  return (
    <article
      className="investCont-whyInvest-content23"
      data-aos="fade-left"
      data-aos-duration={aosDuration}
    >
      <div className="investCont-whyInvest-contact-info">
        <header className="investCont-whyInvest-content24">
          <h2 className="investCont-whyInvest-heading10">{name}</h2>
          <p className="investCont-whyInvest-text21">{title}</p>
        </header>
        <address className="investCont-whyInvest-link-parent">
          {email && (
            <a href={`mailto:${email}`} className="investCont-whyInvest-link">
              <img src={EmailIcon} alt="Email Icon" /> {email}
            </a>
          )}
          <p className="investCont-whyInvest-link1">
            <a href={`tel:${phone}`} className="investCont-whyInvest-link">
              <img src={PhoneIcon} alt="Phone Icon" /> {phone}
            </a>
          </p>
        </address>
      </div>
    </article>
  );
}

export default function InvestorContactInfo() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section className="investCont-whyInvest-contact-head">
      <header className="investCont-whyInvest-contact-20">
        <div className="investCont-whyInvest-content3">
          <h1 className="investCont-whyInvest-heading">Contact Information</h1>
        </div>
        <div className="investCont-whyInvest-row1">
          {contactInfo.map((contact, index) => (
            <ContactCard
              key={index}
              name={contact.name}
              title={contact.title}
              email={contact.email}
              phone={contact.phone}
              aosDuration={contact.aosDuration}
            />
          ))}
        </div>
      </header>
    </section>
  );
}
