import React, { useState, useEffect } from "react";
import "./investorCategory.css";
import InvestorWhyImage1 from "../../images/ic-sustainability.webp";
import InvestorWhyImage2 from "../../images/ic-news&announcement.webp";
import InvestorWhyImage3 from "../../images/ic-analysts coverage.webp";
import InvestorWhyImage4 from "../../images/ic-investorinfo.webp";
import InvestorWhyImage5 from "../../images/ic-corporateinfo.webp";
import InvestorWhyImage6 from "../../images/ic-reports&presentations.webp";

const slideWidth = 30;

const _items = [
  {
    player: {
      title: "Sustainability",
      image: InvestorWhyImage1,
      link: "/sustainability/",
    },
  },
  {
    player: {
      title: "News & Announcement",
      image: InvestorWhyImage2,
      link: "/media-room/",
    },
  },
  {
    player: {
      title: "Reports & Presentations",
      image: InvestorWhyImage6,
      link: "/reports-and-presentation/",
    },
  },
  {
    player: {
      title: "Investor Information",
      image: InvestorWhyImage4,
      link: "/investor-desk/",
    },
  },
  {
    player: {
      title: "Analyst Coverage",
      image: InvestorWhyImage3,
      link: "/analyst-coverage/",
    },
  },
  {
    player: {
      title: "Corporate Information",
      image: InvestorWhyImage5,
      link: "/corporate-information/",
    },
  },
];

const length = _items.length;
const infiniteItems = [..._items, ..._items];

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position, idx) => {
  const item = {
    styles: {
      transform: `translateX(${position * slideWidth}rem)`,
    },
    player: infiniteItems[idx].player,
  };

  if (position === length - 1 || position === length + 1) {
    item.styles = { ...item.styles, filter: "grayscale(1)" };
  } else if (position !== length) {
    item.styles = { ...item.styles, opacity: 0 };
  }

  return item;
};

const CarouselSlideItem = ({ pos, idx }) => {
  const item = createItem(pos, idx);

  return (
    <li className="investCat-carousel__slide-item" style={item.styles}>
      <div className="investCat-carousel__slide-item-img-link">
        <a href={item.player.link} rel="noopener noreferrer">
          <img src={item.player.image} alt={item.player.title} />
        </a>
      </div>
      <div className="investCat-carousel-slide-item__body">
        <h4 className="investCat-title">{item.player.title}</h4>
      </div>
    </li>
  );
};

const InvestorCategory = () => {
  const [items, setItems] = useState(
    Array.from(Array(infiniteItems.length).keys())
  );
  const [isTicking, setIsTicking] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const bigLength = items.length;

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => prev.map((_, i) => prev[(i + jump) % bigLength]));
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) =>
        prev.map((_, i) => prev[(i - jump + bigLength) % bigLength])
      );
    }
  };

  const handleDotClick = (idx) => {
    if (idx < activeIdx) prevClick(activeIdx - idx);
    if (idx > activeIdx) nextClick(idx - activeIdx);
  };

  useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  useEffect(() => {
    setActiveIdx((length - (items[0] % length)) % length);
  }, [items]);

  // Auto-slide effect with reset for infinite effect
  useEffect(() => {
    const interval = setInterval(() => {
      nextClick();
    }, 3000); // Adjust the interval duration as needed

    return () => clearInterval(interval); // Clear the interval on unmount
  }, []);

  return (
    <div className="inverstorCat-slider">
      <div className="investCat-carousel__wrap">
        <div className="investCat-carousel__inner">
          <button
            className="investCat-carousel__btn investCat-carousel__btn--prev"
            onClick={() => prevClick()}
          >
            <i className="investCat-carousel__btn-arrow investCat-carousel__btn-arrow--left" />
          </button>
          <div className="investCat-carousel__container">
            <ul className="investCat-ul investCat-carousel__slide-list">
              {items.map((pos, i) => (
                <CarouselSlideItem key={i} idx={i} pos={pos} />
              ))}
            </ul>
          </div>
          <button
            className="investCat-carousel__btn investCat-carousel__btn--next"
            onClick={() => nextClick()}
          >
            <i className="investCat-carousel__btn-arrow investCat-carousel__btn-arrow--right" />
          </button>
          <div className="investCat-carousel__dots">
            {items.slice(0, length).map((pos, i) => (
              <button
                key={i}
                onClick={() => handleDotClick(i)}
                className={
                  i === activeIdx ? "investCat-dot active" : "investCat-dot"
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorCategory;

// import React from 'react'
// import './investorCategory.css'
// import InvestorWhyImage1 from "../../images/rectangle-150@2x.png"
// import InvestorWhyImage2 from "../../images/vector-27.svg"
// import InvestorWhyImage3 from "../../images/rectangle-1501@2x.png"
// import InvestorWhyImage4 from "../../images/rectangle-1502@2x.png"

// export default function InvestorCategory() {
//     return (
//         <section className="investCat-contact-head">
//             <header className="investCat-contact-20">
//                 <div className="investCat-row1">
//                     <article className="investCat-content231" style={{ position: 'relative' }}>
//                         <img
//                             src={InvestorWhyImage4}
//                             alt="Investor Image"
//                             style={{
//                                 width: '490px',
//                                 height: '310px',
//                             }}
//                         />
//                         <div
//                             className="investCat-contact-info"
//                             style={{
//                                 position: 'absolute',
//                                 top: '50%',
//                                 left: '50%',
//                                 transform: 'translate(-50%, -50%)',
//                                 color: '#fff',
//                                 textAlign: 'center'
//                             }}
//                         >
//                             <address className="investCat-link-parent">
//                                 <h2 className="investCat-heading10">Sustainability</h2>
//                             </address>
//                         </div>
//                     </article>

//                     <article className="investCat-content232" style={{ position: 'relative' }}>
//                         <img
//                             src={InvestorWhyImage3}
//                             alt="Investor Image"
//                             style={{
//                                 width: '490px',
//                                 height: '310px',
//                             }}
//                         />
//                         <div
//                             className="investCat-contact-info"
//                             style={{
//                                 position: 'absolute',
//                                 top: '50%',
//                                 left: '50%',
//                                 transform: 'translate(-50%, -50%)',
//                                 color: '#fff',
//                                 textAlign: 'center'
//                             }}
//                         >
//                             <address className="investCat-link-parent">
//                                 <h2 className="investCat-heading10">News & Announcement</h2>
//                             </address>
//                         </div>
//                     </article>

//                     <article className="investCat-content23" style={{ position: 'relative' }}>
//                         <img
//                             src={InvestorWhyImage4}
//                             alt="Investor Image"
//                             style={{
//                                 width: '490px',
//                                 height: '310px',
//                             }}
//                         />
//                         <div
//                             className="investCat-contact-info"
//                             style={{
//                                 position: 'absolute',
//                                 top: '50%',
//                                 left: '50%',
//                                 transform: 'translate(-50%, -50%)',
//                                 color: '#fff',
//                                 textAlign: 'center'
//                             }}
//                         >
//                             <address className="investCat-link-parent">
//                                 <h2 className="investCat-heading10">Reports & Presentations</h2>
//                             </address>
//                         </div>
//                     </article>

//                     <article className="investCat-content23" style={{ position: 'relative' }}>
//                         <img
//                             src={InvestorWhyImage1}
//                             alt="Investor Image"
//                             style={{
//                                 width: '400px',
//                                 height: '300px',
//                             }}
//                         />
//                         <div
//                             className="investCat-contact-info"
//                             style={{
//                                 position: 'absolute',
//                                 top: '50%',
//                                 left: '50%',
//                                 transform: 'translate(-50%, -50%)',
//                                 color: '#fff',
//                                 textAlign: 'center'
//                             }}
//                         >
//                             <address className="investCat-link-parent">
//                                 <h2 className="investCat-heading10">Investor Information</h2>
//                             </address>
//                         </div>
//                     </article>

//                     <article className="investCat-content23" style={{ position: 'relative' }}>
//                         <img
//                             src={InvestorWhyImage1}
//                             alt="Investor Image"
//                             style={{
//                                 width: '400px',
//                                 height: '300px',
//                             }}
//                         />
//                         <div
//                             className="investCat-contact-info"
//                             style={{
//                                 position: 'absolute',
//                                 top: '50%',
//                                 left: '50%',
//                                 transform: 'translate(-50%, -50%)',
//                                 color: '#fff',
//                                 textAlign: 'center'
//                             }}
//                         >
//                             <address className="investCat-link-parent">
//                                 <h2 className="investCat-heading10">Analyst Coverage</h2>
//                             </address>
//                         </div>
//                     </article>

//                     <article className="investCat-content23" style={{ position: 'relative' }}>
//                         <img
//                             src={InvestorWhyImage1}
//                             alt="Investor Image"
//                             style={{
//                                 width: '400px',
//                                 height: '300px',
//                             }}
//                         />
//                         <div
//                             className="investCat-contact-info"
//                             style={{
//                                 position: 'absolute',
//                                 top: '50%',
//                                 left: '50%',
//                                 transform: 'translate(-50%, -50%)',
//                                 color: '#fff',
//                                 textAlign: 'center'
//                             }}
//                         >
//                             <address className="investCat-link-parent">
//                                 <h2 className="investCat-heading10">Corporate Information</h2>
//                             </address>
//                         </div>
//                     </article>

//                 </div>
//             </header>
//         </section>
//       );
// }
