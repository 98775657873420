import React from "react";
import "./investorShareInfo.css";
import Vector5Arrow from "../../images/Vector5Arrow.png";

const InvestorShareInformation = () => {
  return (
    <div
      className="share-info-section"
      data-aos="zoom-in"
      data-aos-duration="750"
    >
      <div className="text-and-button">
        <h2>Share Information</h2>
        <div>
          <a href="/share-information">
            <button className="read-more-button hover-effect">
              Read More
              <span className="shareinfo-arrow hover-effect"></span>
            </button>
          </a>
        </div>
      </div>
      <div className="right-container">
        <div className="info-buttons">
          <button className="info-button">
            CIC.N0000{" "}
            <span className="circle-arrow">
              <img className="vector5arrow" alt="loading" src={Vector5Arrow} />
            </span>
          </button>
          <button className="info-button">
            CIC.X0000{" "}
            <span className="circle-arrow">
              <img className="vector5arrow" alt="loading" src={Vector5Arrow} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestorShareInformation;
