import React, { lazy, useState, Suspense } from "react";
import ScrollTrigger from "react-scroll-trigger";
import "./card.css";

import GreenCircle from "./GreenCircle";
import GrayCircle from "./GrayCircle";
// import HalfCircleMeterTest from "./HalfCircleMeterTest"

import Test from "./BarChart";
import WhiteCircle from "./WhiteCircle";
import NumberCard from "./NumberCard";

const CountUp = lazy(() => import("react-countup"));

const TwoColumnParagraph = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <center>
      <div style={{ width: "83%", marginTop: "50px", alignItems: "start" }}>
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          <div className="main-card-title">Investor Statistics</div>
          <div className="container-t">
            <div className="two-column-1">
              <div className="column-para-space">
                <p>Rs. Billion</p>
                <p className="values-loader">
                  {counterOn && (
                    <Suspense fallback={<div></div>}>
                      <CountUp
                        start={0}
                        end={16.27}
                        decimals={2}
                        duration={2}
                        delay={0}
                      />
                    </Suspense>
                  )}
                </p>
                <Test />
              </div>
            </div>
            <div className="two-column-2">
              <div className="column-para-space">
                <WhiteCircle />
              </div>
            </div>
          </div>
          <div className="container-t">
            <div className="market-capital-two-column">
              {/* <h2>Market Capitalization</h2> */}
              <div className="two-column-3">
                <div className="container-2">
                  <div className="two-column-5">
                    <div className="column-para-space">
                      <GreenCircle />
                    </div>
                  </div>
                  <div className="two-column-6">
                    <div className="column-para-space">
                      <GrayCircle />
                    </div>
                  </div>
                </div>
              </div>
              <a href="/market-capitalization" className="market-card-read-more market-hover-effect">
                  Market Capitalization
                <span className="market-card-arrow market-hover-effect"></span>
              </a>
            </div>
            <div className="two-column-4">
              <div className="column-para-space">
                <NumberCard />
                <a
                  href="/dividend-information"
                  className="market-card-read-more-dividend-information market-hover-effect"
                >
                  Dividend Information
                  <span className="market-card-arrow market-hover-effect"></span>
                </a>
              </div>
            </div>

          </div>
        </ScrollTrigger>
      </div>
    </center>
  );
};

export default TwoColumnParagraph;