import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ScrollTrigger from "react-scroll-trigger";
import "react-circular-progressbar/dist/styles.css";

const SemiCircleChart = ({
  targetValue,
  text,
  centerText,
  color,
  currencyLabel,
  animate,
}) => {
  const [value, setValue] = useState(0);
  const [animatedCenterText, setAnimatedCenterText] = useState(0);

  useEffect(() => {
    if (animate) {
      const valueAnimation = setInterval(() => {
        setValue((prevValue) => {
          if (prevValue < targetValue) {
            return prevValue + 1;
          } else {
            clearInterval(valueAnimation);
            return targetValue;
          }
        });
      }, 20); // Adjust the interval speed for faster/slower animation

      return () => clearInterval(valueAnimation);
    } else {
      setValue(0); // Reset if not animating
    }
  }, [targetValue, animate]);

  useEffect(() => {
    if (animate) {
      const textAnimation = setInterval(() => {
        setAnimatedCenterText((prevText) => {
          if (prevText < parseFloat(centerText)) {
            return parseFloat((prevText + 0.1).toFixed(1));
          } else {
            clearInterval(textAnimation);
            return parseFloat(centerText);
          }
        });
      }, 8); // Adjust the interval speed for faster/slower animation

      return () => clearInterval(textAnimation);
    } else {
      setAnimatedCenterText(0); // Reset if not animating
    }
  }, [centerText, animate]);

  return (
    <div style={{ width: "150px", margin: "0 10px", position: "relative" }}>
      <CircularProgressbar
        value={value}
        text={""}
        styles={buildStyles({
          rotation: 0,
          strokeLinecap: "round",
          textSize: "16px",
          pathTransitionDuration: 0.5,
          pathColor: color,
          trailColor: "#ffffff00",
        })}
      />

      <div
        style={{
          position: "absolute",
          top: "37%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "20px",
          fontWeight: "bold",
          color: color,
          zIndex: 1,
          pointerEvents: "none",
        }}
      >
        {animatedCenterText}
      </div>

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "12px",
          color: color,
          pointerEvents: "none",
        }}
      >
        {currencyLabel}
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "8px",
          fontWeight: "bold",
          color,
        }}
      >
        {text}
      </div>
    </div>
  );
};

const GreenDashboard = () => {
  const [counterOn, setCounterOn] = useState(false);
  const data = [
    {
      targetValue: 70,
      text: "Voting Shares",
      centerText: "22.3",
      currencyLabel: "Rs. Billion",
      color: "#fff",
    },
  ];

  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        {data.map((item, index) => (
          <SemiCircleChart
            key={index}
            targetValue={item.targetValue}
            text={item.text}
            centerText={item.centerText}
            currencyLabel={item.currencyLabel}
            color={item.color}
            animate={counterOn} 
          />
        ))}
      </div>
    </ScrollTrigger>
  );
};

export default GreenDashboard;
