import React, { useEffect } from 'react'
import './investorWhyInvest.css'
import Aos from "aos";
import "aos/dist/aos.css";

export default function InvestorWhyInvest() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className='whyInvest'>
      <div className='whyInvest-container'>
        <h1 className='whyInvest-heading' data-aos="fade-right" data-aos-duration="500">
          Why Invest 
          <span className="whyInvest-topic-color">{" "}in CIC ?</span>
        </h1>
        <p className='whyInvest-para' data-aos="fade-bottom" data-aos-duration="600">
          CIC Holdings PLC provides a unique investment opportunity in the 
          Sri Lankan Agri Produce, Health and Personal Care, Livestock Solutions, 
          Industrial Solutions and Crop Solutions markets.{" "}
          <span data-aos="fade-up" data-aos-duration="1100"> 
          Our strength can be attributed 
          directly to its strict adherence to a unitary vision to improve society by nurturing 
          the lives of those we touch.{" "}
          </span>
          <span data-aos="zoom-in" data-aos-duration="1500">
          We at CIC believe in sustainable and ethical 
          business practices and adherence to the highest international standards, 
          so that we provide the best possible outcome for our customers, our partners, 
          as well as our investors.  
          </span> 
        </p>
      </div>
    </div>
  )
}
