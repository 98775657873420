import React, { useState, useRef } from "react";
import ScrollTrigger from "react-scroll-trigger";
import "./BarChart.css";

const Chart = ({ waterLevel }) => {
  const [waterPercentage, setWaterPercentage] = useState(0);
  const chartSectionRef = useRef(null);

  // Function to trigger the animation
  const handleEnter = () => {
    const waterHeight = (waterLevel / 100) * 150; // Calculate water height based on percentage
    setWaterPercentage(waterHeight);
  };

  // Reset water level when the section exits
  const handleExit = () => {
    setWaterPercentage(0);
  };

  return (
    <div className="chart-section" ref={chartSectionRef}>
      <ScrollTrigger
        onEnter={handleEnter}
        onExit={handleExit}
        triggerOnce={false} // Ensures the animation happens every time the section is in view
      >
        <div className="bar-chart-container">
          <div className="bar-chart">
            <div className="water" style={{ height: waterPercentage }} />
          </div>
        </div>
      </ScrollTrigger>
    </div>
  );
};

const BarChart = () => {
  return (
    <div className="bar-chart-row">
      <Chart waterLevel={16} />
      <Chart waterLevel={32} />
      <Chart waterLevel={48} />
      <Chart waterLevel={64} />
      <Chart waterLevel={80} />
      <Chart waterLevel={100} />
    </div>
  );
};

export default BarChart;
