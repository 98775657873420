import React from 'react'
import './investorMainBanner.css'
// import InvestorImage from "../../images/InvestorMainBanner1.png"
import InvestorImage from "../../images/InvestorMainBanner.webm"

export default function InvestorMainBanner() {
  return (
    <div>
      <div>
        <div className="investorMain">
          {/* <img className="investorMain-home-image" alt="" src={InvestorImage} /> */}
          <video 
            className="investorMain-home-image" 
            src={InvestorImage}
            autoPlay
            loop
            muted 
          />
          <div className="investorMain-image-text-box">
            <div className="investorMain-image-background-text">
              <h1 className="investorMain-image-topic">
              Investor Desk
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
