import React, { useEffect, useState } from "react";
import "./WhiteCircle.css";

const WhiteCircle = () => {
  const [offset, setOffset] = useState(0);
  const percentage = 40; // Control how much of the circle is filled (0-100%)
  const circleRadius = 90;
  const circleCircumference = Math.PI * circleRadius; // Full circumference

  useEffect(() => {
    setOffset(0); // Initially no fill
    setTimeout(() => {
      setOffset(circleCircumference * (percentage / 100)); // Animates fill based on percentage
    }, 10);
  }, [percentage, circleCircumference]);

  return (
    <div className="white-circle-container">
      <div className="white-circle-title">
        Profit in Q4 <br /> 2023/24
      </div>
      <svg
        className="white-circle-svg"
        width="400"
        height="150"
        viewBox="0 0 200 100"
      >
        {/* Background Path (Light Transparent Color) */}
        <path
          d="M 10 100 A 90 90 0 0 1 190 100" // Draw half circle from left to right
          fill="none"
          stroke="rgba(255, 255, 255, 0.5)" // Light transparent white
          strokeWidth="8"
          strokeLinecap="round" // Rounded ends
          strokeLinejoin="round" // Rounded joins
        />
        {/* Animated Path (Darker White for the filling effect) */}
        <path
          d="M 10 100 A 90 90 0 0 1 190 100" // Same half circle path to animate the filling
          fill="none"
          stroke="white" // Darker white for the animated fill
          strokeWidth="8"
          strokeDasharray={circleCircumference} // Full circumference (half circle)
          strokeDashoffset={circleCircumference - offset} // Offset to animate from left to right
          strokeLinecap="round" // Ensure rounded ends for the path
          strokeLinejoin="round" // Ensure rounded joins
          className="animated-arc"
        />
      </svg>

      <div className="center-text">
        <div className="white-circle-profit">1.35</div>
      </div>
      <div className="white-circle-details">
        <div className="white-circle-currency">Rs. Billion</div>
        <div>
          <a href="/financial-highlights" className="card-read-more hover-effect">
            Read More
            <span className="card-arrow hover-effect"></span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WhiteCircle;
