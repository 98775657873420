import React, { useState, useEffect, useRef } from "react";
import "./NumberCard.css";

const NumberCard = () => {
  const [perShare, setPerShare] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const cardRef = useRef(null); // Reference to the component

  useEffect(() => {
    const animateValue = (setValue, endValue, duration) => {
      let start = 0;
      const increment = endValue / (duration / 16);
      const animate = () => {
        start += increment;
        if (start < endValue) {
          setValue(parseFloat(start.toFixed(2)));
          requestAnimationFrame(animate);
        } else {
          setValue(endValue);
        }
      };
      animate();
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          animateValue(setPerShare, 2.5, 2000);
          animateValue(setTotalValue, 947.7, 2000);
        }
      },
      { threshold: 0.5 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div className="number-card" ref={cardRef}>
      <div className="per-share">
        <p>Per Shares</p>
        <h1>Rs. {perShare.toFixed(2)}</h1>
      </div>
      <div className="total-value">
        <p>Total Value</p>
        <h1>
          <span style={{ width: "180px", fontSize: "48px", textAlign: "left" }}>
            {totalValue.toFixed(2)}
          </span>{" "}
          <span style={{ width: "70px" }}>Rs.&nbsp;Million</span>
        </h1>
      </div>
    </div>
  );
};

export default NumberCard;
